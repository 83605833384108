<template>
  <div
    v-if="contents && selectedCarouselItem !== null"
    class="screensaver"
    style="width: 100%;height:100%;"
  >
    <v-carousel 
      v-model="selectedCarouselItem"
      :cycle="false"
      :show-arrows="false" 
      hide-delimiters
      height="100%"
    >
      <v-carousel-item
        v-for="(item, i) in contents"
        :key="i"
        v-html="item.content"
      />
    </v-carousel>

  </div>
</template>
<script>
export default {
  name: 'CustomCarousel',
  props: {
    contents: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    timer: null,
    selectedCarouselItem: null,
    animationTime: '50s', // only messages
  }),
  watch: {
  },
  mounted () {
    this.selectedCarouselItem = 0
    this.handleInterval()
  },
  methods: {
    handleInterval () {
      const content = this.contents[this.selectedCarouselItem]
      const duration = content ? content.duration * 1000 : 0
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.handleChangeContent()
      }, duration)
    },
    handleChangeContent () {
      this.selectedCarouselItem = this.selectedCarouselItem < this.contents.length-1 ? this.selectedCarouselItem + 1 : 0
      this.handleInterval () 
    },
  },
}
</script>
<style>
.message-preview {
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.p-preview {
    display: inline-block;
    padding-left: 100%;
    animation: move linear infinite;
}
@keyframes move {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}
</style>

